<template>
    <div class="connected-btn">
        <div class="connected-btn-tooltip tooltip-main-data">
            <a
                class="list-btn"
                :class="{ 'active-class': !timelineView }"
                role="radio"
                :aria-checked="!timelineView ? 'true' : 'false'"
                :aria-disabled="!timelineView ? 'true' : 'false'"
                aria-label="Organize as list"
                title="Organize as list"
                href="javascript:void(0)"
                v-on:click="
                    timelineView = false;
                    listView = true;
                "
            >
                <img class="btn-normal" src="@/assets/list.svg" alt="" />
                <img class="btn-active" src="@/assets/list-active.svg" alt="" />
            </a>
            <div class="tooltip-data">
                <p class="t-texts" title="List View">
                    <span>{{ $t("EntityViewSelector.list_view") }}</span>
                </p>
            </div>
        </div>

        <div class="connected-btn-tooltip tooltip-main-data list-view-tooltip">
            <a
                class="list-btn"
                :class="{ 'active-class': timelineView }"
                href="javascript:void(0)"
                role="radio"
                :aria-checked="!listView ? 'true' : 'false'"
                :aria-disabled="!listView ? 'true' : 'false'"
                aria-label="Organize as timeline"
                title="Organize as timeline"
                v-on:click="
                    timelineView = true;
                    listView = false;
                "
            >
                <img
                    class="btn-normal"
                    src="@/assets/timeline.svg"
                    alt="Timeline icon"
                />
                <img
                    class="btn-active"
                    src="@/assets/timeline-active.svg"
                    alt="Timeline icon"
                />
            </a>
            <div class="tooltip-data">
                <p class="t-texts" title="Timeline View">
                    <span>{{ $t("EntityViewSelector.timeline_view") }}</span>
                </p>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";

@Options({
    watch: {
        timelineView() {
            this.$emit("update", { timelineView: this.timelineView });
        },
        listView() {
            this.$emit("update", { listView: this.listView });
        },
    },
})
export default class EntityViewSelector extends Vue {
    timelineView = false;
    listView = true;
}
</script>
<style>
.connected-btn {
    position: relative;
    display: flex;
    align-items: center;
}

.tooltip-main-data.connected-btn-tooltip .tooltip-data {
    bottom: -32px;
    left: 5px;
    width: 55px;
}

.tooltip-main-data.connected-btn-tooltip.list-view-tooltip .tooltip-data {
    bottom: -32px;
    left: inherit;
    right: 0px;
    width: 80px;
}

.tooltip-main-data.connected-btn-tooltip.list-view-tooltip
    .tooltip-data:before {
    left: inherit;
    right: 5px;
}

.list-btn {
    display: block;
    margin-left: 10px;
    position: relative;
    width: 24px;
    height: 24px;
    overflow: hidden;
    border-radius: 3px;
    border: solid 1px #dddd;
}

.list-btn img {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    opacity: 0;
    transform: translate(-50%, -50%);
}

.list-btn img.btn-normal {
    opacity: 1;
}

.list-btn:hover {
    background-color: #edf6f8;
    border-color: #edf6f8;
}

.list-btn:hover .btn-normal {
    visibility: visible;
    opacity: 1;
}

.list-btn.active-class {
    background-color: #107386;
    border-color: #107386;
}

.list-btn.active-class .btn-active {
    opacity: 1;
    visibility: visible;
    z-index: 10;
}

.list-btn.active-class .btn-normal {
    opacity: 0;
    visibility: hidden;
}

.tooltip-main-data {
    width: auto;
    display: inline-block;
}

.tooltip-data {
    display: none;
    position: absolute;
    width: 200px;
    background-color: #081920;
    padding: 5px 5px;
    z-index: 999;
    bottom: -35px;
    left: 0px;
}

.tooltip-data:before {
    content: "";
    position: absolute;
    top: -7px;
    left: 10px;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #081920;
}

.tooltip-data .t-texts {
    margin: 0;
    font-size: 12px;
    color: #ffffff;
}

.tooltip-data .t-texts span {
    display: inline-block;
    text-align: left;
    margin-bottom: 10px;
}

.tooltip-data .t-texts span:last-child {
    margin-bottom: 0px;
}
</style>
