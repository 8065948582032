const typeMapperEN: any = {
    E39_ACTOR: "Groups",
    E74_GROUP: "Groups",
    E21_PERSON: "People",
    E53_PLACE: "Places",
    "E22_HUMAN-MADE_OBJECT": "Creative Works",
    F1_WORK: "Creative Works",
    "CORE#CONCEPT": "Concepts",
    E33_LINGUISTIC_OBJECT: "Web References",
};

const typeMapperFR: any = {
    E39_ACTOR: "Groupes",
    E74_GROUP: "Groupes",
    E21_PERSON: "Personnes",
    E53_PLACE: "Lieux",
    "E22_HUMAN-MADE_OBJECT": "Œuvres Créatives",
    F1_WORK: "Œuvres Créatives",
    "CORE#CONCEPT": "Concepts",
    E33_LINGUISTIC_OBJECT: "Références Web",
};

export const getEntityTypeName = (key: string, locale: boolean) => {
    if (locale) {
        return typeMapperEN[key.toUpperCase()] || "Other";
    } else {
        return typeMapperFR[key.toUpperCase()] || "Autre";
    }
};
