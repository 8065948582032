<template>
    <div :class="{
            'no-results-content-small': !fullLength,
            'no-results-content-big': fullLength,
        }">
        <img src="@/assets/no-results.svg" class="results-icon" alt="No results icon" />
        <h1 class="results-title">No connections found...</h1>
        <p class="results-info">
            Data is currently unavailable and may still be in progress in the LINCS triplestore.
            <br/><br/>
            If there's an error, report an issue to our 
            <a 
                href='https://uoguelph.eu.qualtrics.com/jfe/form/SV_3QSa6nuyjLlTdI2?Q_PopulateResponse=%7B"QID9":"1","QID4":"10","QID11":"2"%7D&Q_lang=en-ca&Source=Context-Explorer' 
                target="_blank">
                feedback form
            </a>.
        </p>
    </div>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";

@Options({
    props: {
        fullLength: {
            type: Boolean,
            default: false,
        },
    },
})
export default class NoResults extends Vue {
    fullLength!: boolean;
}
</script>
<style>
.no-results-content-small {
    margin-top: 15px;
}

.no-results-content-big {
    margin-top: 80px;
}

.results-icon {
    width: 105px;
    height: 105px;
}

.results-title {
    font-size: 16px;
}

.results-info {
    font-size: 14px;
    padding-left: 15px;
    padding-right: 15px;
}
</style>
