<template>
    <div class="annotation-target-card">
        <img
            class="img-fluid annotation-target-icon"
            :src="image"
            alt="Target Favicon"
        />
        <div class="annotation-target-text-wrapper">
            <a
                class="wrap-text annotation-target-link"
                :href="target"
                target="_blank"
            >
                {{ title }}
                <img
                    class="img-fluid"
                    src="@/assets/external-link-line.svg"
                    alt="External link"
                />
            </a>
            <p class="annotation-target-text">{{ target }}</p>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";

@Options({
    props: {
        target: String,
        image: String,
        title: String,
    },
})
export default class AnnotationTargetCard extends Vue {
    target!: string;
    image!: string;
    title!: string;
}
</script>
<style>
.annotation-target-card {
    width: 307px;
    border: 1px solid black;
    padding: 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.annotation-target-icon {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
    margin-left: 5px;
}
.annotation-target-text-wrapper {
    width: 257px;
}
.annotation-target-link {
    cursor: pointer;
}
.annotation-target-text {
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
    text-wrap: nowrap;
    margin: 0px;
    color: #5e6364;
}
</style>
