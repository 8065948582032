import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createI18n } from "vue-i18n";
import { translations } from "./helper/Translations";

const app = createApp(App).use(router);

// Include Matomo tracking script
const matomoScript = document.createElement("script");
matomoScript.type = "text/javascript";
matomoScript.innerHTML = `
  var _paq = window._paq = window._paq || [];
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);
  (function() {
    var u="//matomo.lincsproject.ca/";
    _paq.push(['setTrackerUrl', u+'matomo.php']);
    _paq.push(['setSiteId', '4']);
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
  })();
`;
document.head.appendChild(matomoScript);

// Set up internationalization
const i18n = createI18n({
    locale: "en", // set locale
    fallbackLocale: "en", // set fallback locale
    messages: translations, // set locale messages
});
app.use(i18n);

app.mount("#app");
