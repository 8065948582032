import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { value: "all" }
const _hoisted_2 = { value: "desc" }
const _hoisted_3 = { value: "asc" }
const _hoisted_4 = { class: "timeline-section" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "timeline-wrapper" }
const _hoisted_7 = { class: "timeline-date" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "timeline-wrapper" }
const _hoisted_10 = { class: "timeline-date" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EntityResourceCard = _resolveComponent("EntityResourceCard")!

  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createElementVNode("select", {
      class: "type-filter width-100",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dateFilter) = $event))
    }, [
      _createElementVNode("option", _hoisted_1, _toDisplayString(_ctx.$t("EntityTimeline.sort_by")) + ":", 1),
      _createElementVNode("option", _hoisted_2, _toDisplayString(_ctx.$t("EntityTimeline.newest_to_oldest")), 1),
      _createElementVNode("option", _hoisted_3, _toDisplayString(_ctx.$t("EntityTimeline.oldest_to_newest")), 1)
    ], 512), [
      [_vModelSelect, _ctx.dateFilter]
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.entries(
                    _ctx.sortedEntitiesByDate,
                ), (dateCategory, index) => {
        return (_openBlock(), _createElementBlock("div", { key: index }, [
          (
                        dateCategory[0] === 'dated' &&
                        (dateCategory[1] as Array<any>).length > 0
                    )
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dateCategory[1], (datedEntity, datedEntityIndex) => {
                  return (_openBlock(), _createElementBlock("div", { key: datedEntityIndex }, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("h3", _hoisted_7, _toDisplayString(datedEntity[0]), 1),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(datedEntity[1], (displayDatedEntity, displayDatedEntityIndex) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: `entity-${displayDatedEntityIndex}`,
                          class: "entity-details-wrapper"
                        }, [
                          _createVNode(_component_EntityResourceCard, {
                            data: displayDatedEntity,
                            index: displayDatedEntityIndex,
                            currentExpandedIndex: _ctx.expandedEntityIndex,
                            onUpdate: 
                                        (values) =>
                                            (
                                                _ctx.$parent as any
                                            ).handleToggleOfDetails(
                                                values.displayDatedEntityIndex,
                                                values.mainEntity,
                                            )
                                    
                          }, null, 8, ["data", "index", "currentExpandedIndex", "onUpdate"])
                        ]))
                      }), 128))
                    ])
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]))
      }), 128)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.entries(
                    _ctx.sortedEntitiesByDate,
                ), (dateCategory, index) => {
        return (_openBlock(), _createElementBlock("div", { key: index }, [
          (
                        dateCategory[0] === 'undated' &&
                        (dateCategory[1] as Array<any>).length > 0
                    )
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("h3", _hoisted_10, _toDisplayString(_ctx.$t("EntityTimeline.undated")), 1),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dateCategory[1], (undatedEntity, undatedIndex) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "entity-details-wrapper",
                      key: `entity-${undatedIndex}`
                    }, [
                      _createVNode(_component_EntityResourceCard, {
                        data: undatedEntity,
                        index: undatedIndex,
                        currentExpandedIndex: _ctx.expandedEntityIndex,
                        onUpdate: 
                                    (values) =>
                                        (_ctx.$parent as any).handleToggleOfDetails(
                                            values.undatedIndex,
                                            values.mainEntity,
                                        )
                                
                      }, null, 8, ["data", "index", "currentExpandedIndex", "onUpdate"])
                    ]))
                  }), 128))
                ])
              ]))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ])
  ]))
}