<template>
    <div>
        <select class="type-filter width-100" v-model="dateFilter">
            <option value="all">{{ $t("EntityTimeline.sort_by") }}:</option>
            <option value="desc">
                {{ $t("EntityTimeline.newest_to_oldest") }}
            </option>
            <option value="asc">
                {{ $t("EntityTimeline.oldest_to_newest") }}
            </option>
        </select>
        <div class="timeline-section">
            <div
                v-for="(dateCategory, index) in Object.entries(
                    sortedEntitiesByDate,
                )"
                :key="index"
            >
                <div
                    v-if="
                        dateCategory[0] === 'dated' &&
                        (dateCategory[1] as Array<any>).length > 0
                    "
                >
                    <div
                        v-for="(
                            datedEntity, datedEntityIndex
                        ) in dateCategory[1]"
                        :key="datedEntityIndex"
                    >
                        <div class="timeline-wrapper">
                            <h3 class="timeline-date">
                                {{ datedEntity[0] }}
                            </h3>
                            <div
                                v-for="(
                                    displayDatedEntity, displayDatedEntityIndex
                                ) in datedEntity[1]"
                                :key="`entity-${displayDatedEntityIndex}`"
                                class="entity-details-wrapper"
                            >
                                <EntityResourceCard
                                    :data="displayDatedEntity"
                                    :index="displayDatedEntityIndex"
                                    :currentExpandedIndex="expandedEntityIndex"
                                    v-on:update="
                                        (values: any) =>
                                            (
                                                $parent as any
                                            ).handleToggleOfDetails(
                                                values.displayDatedEntityIndex,
                                                values.mainEntity,
                                            )
                                    "
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                v-for="(dateCategory, index) in Object.entries(
                    sortedEntitiesByDate,
                )"
                :key="index"
            >
                <div
                    v-if="
                        dateCategory[0] === 'undated' &&
                        (dateCategory[1] as Array<any>).length > 0
                    "
                >
                    <div class="timeline-wrapper">
                        <h3 class="timeline-date">
                            {{ $t("EntityTimeline.undated") }}
                        </h3>
                        <div
                            class="entity-details-wrapper"
                            v-for="(
                                undatedEntity, undatedIndex
                            ) in dateCategory[1]"
                            :key="`entity-${undatedIndex}`"
                        >
                            <EntityResourceCard
                                :data="undatedEntity"
                                :index="undatedIndex"
                                :currentExpandedIndex="expandedEntityIndex"
                                v-on:update="
                                    (values: any) =>
                                        ($parent as any).handleToggleOfDetails(
                                            values.undatedIndex,
                                            values.mainEntity,
                                        )
                                "
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";
import EntityResourceCard from "../components/EntityResourceCard.vue";

@Options({
    props: {
        parsedEntityInfo: Object,
        expandedEntityIndex: Number,
    },
    components: {
        EntityResourceCard,
    },
})
export default class EntityTimeline extends Vue {
    parsedEntityInfo!: any;
    expandedEntityIndex!: number;

    dateFilter = "asc";
    entitiesByDate: any = {};

    // Entities sorted by date
    get sortedEntitiesByDate() {
        if (this.dateFilter === "asc" || this.dateFilter === "desc") {
            if (this.entitiesByDate.dated) {
                const sortedEntities = {
                    dated: [...this.entitiesByDate.dated],
                    ...this.entitiesByDate,
                };
                this.sortByDate(sortedEntities.dated, this.dateFilter);
                return sortedEntities;
            }
        }
        return this.entitiesByDate;
    }

    mounted() {
        this.prepareEntitiesByDate();
    }

    // Separate the dated and undated entities
    prepareEntitiesByDate() {
        const dateCategories: any = {};
        dateCategories["undated"] = [];
        dateCategories["dated"] = {};

        for (const entity of this.parsedEntityInfo[
            this.parsedEntityInfo.length - 1
        ].connectedEntities) {
            let formattedDate = null;
            if (entity.startDate) {
                formattedDate = new Date(entity?.startDate)
                    .toISOString()
                    .split("T")[0]
                    .replace(/-/g, "/");
            }
            const category = formattedDate ? formattedDate : "undated";

            if (category !== "undated") {
                if (!dateCategories["dated"][category]) {
                    dateCategories["dated"][category] = [];
                }
                dateCategories["dated"][category].push(entity);
            } else {
                dateCategories["undated"].push(entity);
            }
        }

        dateCategories.dated = Object.entries(dateCategories.dated);
        this.sortByDate(dateCategories.dated);
        this.entitiesByDate = dateCategories;
    }

    // Sort the timeline view of the connected entities by date
    sortByDate(array: any, direction = "asc") {
        const multiplier = direction === "desc" ? -1 : 1;

        array.sort(([dateA]: any, [dateB]: any) => {
            const dateObjA: any = new Date(dateA);
            const dateObjB: any = new Date(dateB);

            return multiplier * (dateObjA - dateObjB);
        });
    }
}
</script>
<style>
.timeline-wrapper {
    position: relative;
    width: auto;
    margin-bottom: 15px;
}

.timeline-wrapper::before {
    content: "";
    position: absolute;
    left: -23px;
    top: 2px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #87b9c3;
}

.timeline-date {
    margin: 0 0 5px 0;
    font-size: 16px;
    font-weight: 700;
}

.timeline-section::before {
    content: "";
    position: absolute;
    left: 0;
    top: 3px;
    width: 1px;
    height: 100%;
    background-color: #ddd;
}

.timeline-section {
    position: relative;
    padding-left: 15px;
    margin-left: 5px;
}
</style>
